import { RefObject, useEffect, useState } from "react";

const useLineClampCheck = (ref: RefObject<HTMLElement>, lines = 2) => {
  const [isClamped, setIsClamped] = useState(false);

  const checkClamping = () => {
    if (!ref.current) return;

    // Use ResizeObserver to detect both size changes and content changes
    const observer = new ResizeObserver(() => {
      const lineHeight = parseFloat(getComputedStyle(ref.current!).lineHeight);
      const maxHeight = lineHeight * lines;
      setIsClamped(ref.current!.scrollHeight > maxHeight);
    });

    observer.observe(ref.current);
    return () => observer.disconnect();
  };

  useEffect(checkClamping, [ref.current, lines]);

  return isClamped;
};

export default useLineClampCheck;
